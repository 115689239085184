<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<MdsEntidad :entidad="entidad" :disabled="!entidad" :key="entidadUpdate"/>	
			<Button label="Guardar" icon="pi pi-check" class="p-button" @click="save" />
		</div>
	</div>

</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsEntidad from '../../components/MdsEntidad.vue';
import MdsService from '../../service/mds.service';

export default {
	data() {
		return {
			id_entidad: this.id,
			entidadUpdate: 0,
			lkpEntidadesTipo: this.$store.state.auth.currentAppCtx.entidades_tipo,
			loading: false,
			fullPageLoading: false,
			entidad: {
				id_entidad: "",
				id_entidad_tipo: 1,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true
			}			
		}
	},
	components: {
		Loading, MdsEntidad
	},
	props: {
		id: {
			type: String,
			required: true,
			default: ''
			}			
	},		
	created(){
		if(this.id != "")
		{
			this.fetchData();
		} else {
			this.openNew();
		}
	},
	methods: {
		openNew() {
			this.entidad = {
				id_entidad: "",
				id_entidad_tipo: 1,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				codigo: "",
				contacto: "",
				nombres: "",
				apellidos: "",
				identificacion1: "",
				identificacion2: "",
				tel_residencia: "",
				direccion_residencia: "",
				tel_movil: "",
				tel_trabajo: "",
				fax_trabajo: "",
				direccion_trabajo: "",
				email1: "",
				email2: "",
				nombre_comercial: "",
				razon_social: "",
				activo: "S",
				activoDisplay: true				
			}; 
			this.entidadUpdate++;
		},		
		save() {						
			this.entidad.activo = this.entidad.activoDisplay ? 'S': 'N';
			this.loading = true;
			MdsService.registrarEntidad(this.$store.state.auth.currentAppCtx, this.entidad).then(data => {
				this.loading = false;
				this.entidad.id_entidad = data.id_entidad;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
			}).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			);
		},
		fetchData () {
			this.error = this.post = null;
			this.id_entidad = this.id;
			this.loading = true;
			MdsService.getEntidades(this.$store.state.auth.currentAppCtx, this.id_entidad).then(data => {				
				this.loading = false;
				this.entidad = data;
				this.entidad.activoDisplay = (data.activo == 'S');
				this.entidadUpdate++;
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		}			
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
